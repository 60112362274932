import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CompShowInicio from './inicio/inicio.js';
import { useEffect, useState } from 'react';



function App() {



  return (
    <BrowserRouter>
      <div className="App">
        <Routes>

          <Route path='/' element={<CompShowInicio />} />

        </Routes>
      </div>
    </BrowserRouter >
  );
}



export default App;
