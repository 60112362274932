import React from 'react';
const CompShowFooter = () => {
    return (

        <footer className="flex md:flex-1 mt-44 md:mt-20 "  >
            <div id="website-footer" className="relative flex-1 z-10 break-word" style={{ color: 'rgb(255, 255, 255)', paddingTop: '0px', paddingBottom: '0px' }}>
                <div className="absolute inset-0 z-10 pointer-events-none">
                    <div className="absolute inset-0 z-10" style={{ backgroundImage: 'linear-gradient(rgb(37, 32, 70) , rgb(74, 63, 140))' }}></div>
                </div>
                <div className="relative z-10 container mx-auto pt-16 lg:pt-32 pb-16 lg:pb-32">
                    <div className="flex flex-col lg:flex-row items-start lg:justify-between gap-12 ml-6">
                        <div className="flex flex-col gap-6 items-start lg:max-w-[30vw]">
                            <a className="grid max-w-full" target="_self" href="/es">
                                <h2 className="heading-small lg:heading-medium max-w-full whitespace-nowrap overflow-hidden overflow-ellipsis" style={{ color: 'rgb(255, 255, 255)', fontFamily: 'var(--header-logo-fontFamily)', fontWeight: 'var(--header-logo-fontWeight)', width: '374.04px' }}>Tauket</h2>
                            </a>
                            <p className="leading-relaxed text-sm">Elevando tu presencia digital, un click a la vez.</p>
                          
                        </div>
                        <div className="flex flex-col gap-12 lg:gap-6 lg:flex-1 lg:items-end">

                            <p className="body-normal lg:text-right whitespace-nowrap">
                                <span>Hecho por&nbsp;</span>
                                <a target="_blank" className="underline hover:text-gray-400 transition-colors" href="https://dicorus.com" style={{ color: 'rgb(255, 255, 255)' }}>Dicorus</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default CompShowFooter;
